import React, {useState} from "react";
import {BaseState, StateKeys} from "../../redux/states/main.state";
import {
  CourseResponse,
  TestResponse,
} from "../../services/api.response.service";
import { Col, Row, Spinner } from "react-bootstrap";
import { Link } from "react-router-dom";
import Languages from "../Languages";
import {
  AppState,
  CheckContentProviderIdExist,
} from "../../services/app.service";
import { RouteEndpoints } from "../../services/route.service";
import CourseCardItem from "../CourseCardItem";
import NoData from "../../pages/main/NoData";
import Loader from "../Loader";
import CourseCardItems from "../CourseCardItems";
import Main from "../../redux/actions/main.action";
import ApiEndpoint from "../../services/api.endpoint.service";


interface props {
  hasSubscribed?: boolean;
  subscriptionState?: number;
  subscribeFunction?: () => void;
  topics: BaseState<Array<CourseResponse>>;
  topic: TestResponse | CourseResponse;
  showCourseDetails: CourseResponse;
  setShowTopicDetails: (test: CourseResponse) => void;
  hasPaid?: boolean;
  paymentUrl?: string;
  getCourseTopic?: (course: CourseResponse) => void;
  setShowCourseDetails?: (course: CourseResponse) => void;
}

const ViewCourse = ({
  hasSubscribed,
  hasPaid,
  paymentUrl,
  subscriptionState,
  subscribeFunction,
  topics,
  topic,
  showCourseDetails,
  setShowTopicDetails,
  getCourseTopic,
  setShowCourseDetails
}: props) => {



  return (
    <>
      {" "}
      <section id="ViewCourse">
        <div className="d-flex flex-column gap-4">
          <h5
            style={{
              fontSize: "20px",
              fontWeight: 600,
            }}
          >
            {showCourseDetails?.title}
          </h5>
          <div className="mt-2 text-body">
            <p className="mb-0">{showCourseDetails?.description}</p>
          </div>
          <div className="mt-2 d-flex justify-content-between mb-3">
            <span className="text-primary d-block line-height mt-0">
              {showCourseDetails?.has_read && (
                <i className="las la-book-reader fs-4" />
              )}
              {showCourseDetails?.has_practice && (
                <i
                  className="las la-dumbbell fs-4"
                  style={{ cursor: "pointer" }}
                />
              )}
            </span>
            {showCourseDetails?.total_topics > 0 && (
              <small className="text-secondary ms-auto">
                <Languages langKey="totalTopics" />{" "}
                <span className="text-primary">
                  {showCourseDetails?.total_topics}
                </span>
              </small>
            )}
          </div>
          {hasSubscribed !== undefined ? (
            <>
              {hasSubscribed ? (
                hasPaid || CheckContentProviderIdExist() ? (
                  <>
                    {(showCourseDetails?.has_read ||
                      showCourseDetails?.has_practice) && (
                      <Link
                        to={RouteEndpoints.COURSE_CONTENT(
                          String(showCourseDetails?.id)
                        )}
                        className="text-decoration-none btn btn-primary"
                      >
                        <Languages langKey="startLearning" />{" "}
                      </Link>
                    )}
                  </>
                ) : (
                  <a
                    href={paymentUrl!}
                    target="_blank"
                    className="text-decoration-none btn btn-primary"
                    rel="noreferrer"
                  >
                    <Languages langKey="payToAccessContent" />{" "}
                  </a>
                )
              ) : (
                <button
                  className="text-decoration-none btn btn-primary"
                  onClick={() =>
                    CheckContentProviderIdExist() ? null : subscribeFunction!()
                  }
                  disabled={
                    subscriptionState === AppState.LOADING ||
                    CheckContentProviderIdExist()
                  }
                >
                  {subscriptionState === AppState.LOADING && (
                    <Spinner
                      animation={"border"}
                      size={"sm"}
                      className="me-1"
                    />
                  )}
                  {CheckContentProviderIdExist() ? (
                    <Languages langKey="contentAdminCourse" />
                  ) : (
                    <Languages langKey="subToStartLearn" />
                  )}
                </button>
              )}
            </>
          ) : (
            <>
              {" "}
              <Link
                to={RouteEndpoints.COURSE_CONTENT(
                  String(showCourseDetails?.id)
                )}
                className="text-decoration-none btn btn-primary"
              >
                <Languages langKey="startLearning" />{" "}
              </Link>
            </>
          )}
          {showCourseDetails?.total_topics > 0 && (
            <Row>
              {(topics.pageState === AppState.LOADED ||
                topics.pageState === AppState.LOADING_MORE) && (
                <>
                  {topics.data ? (
                    <>
                      <Col md={12}>
                        <Row className="mt-3">
                          <h6>Topics</h6>


                          {topics.data.length > 0 ?
                              (

                                  <CourseCardItems
                                      courses={topics.data}
                                      setShowCourseDetails={setShowCourseDetails}
                                      getCourseTopic={getCourseTopic}
                                  />
                                  //
                                  // (topics.data as Array<CourseResponse>)
                                  //   // .filter((e) => e.has_read || e.has_practice)
                                  //   .map((item, i) => (
                                  //     <Col
                                  //       key={i}
                                  //       sm={4}
                                  //       className="mt-3"
                                  //       onClick={() =>
                                  //         setShowTopicDetails(
                                  //           item as unknown as CourseResponse
                                  //         )
                                  //       }
                                  //     >
                                  //       <Link
                                  //         to={RouteEndpoints.COURSE_CONTENT(
                                  //           String(item?.id)
                                  //         )}
                                  //       >
                                  //         <CourseCardItem item={item}
                                  //                         view={item.total_topics==0}
                                  //         />{" "}
                                  //       </Link>
                                  //     </Col>
                                  //   ))
                             )
                              : (
                            <NoData body="noTopic" />
                          )}
                          <Col md={12}>
                            {topics.pageState === AppState.LOADING_MORE && (
                              <Loader noCard imageLoader />
                            )}
                          </Col>


                        </Row>
                      </Col>
                    </>
                  ) : (
                    <NoData title="noTopic" />
                  )}
                </>
              )}
              <Col md={12}>
                {topics.pageState === AppState.LOADING && (
                  <Loader noCard imageLoader />
                )}
              </Col>
            </Row>
          )}{" "}
        </div>
      </section>{" "}
    </>
  );
};

export default ViewCourse;
