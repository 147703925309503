import React, { Dispatch, SetStateAction, useEffect, useState } from "react";
import {
  Card,
  Dropdown,
  DropdownButton,
  Modal,
  Spinner,
} from "react-bootstrap";
import { useDispatch, useSelector } from "react-redux";
import Main from "../../redux/actions/main.action";
import { BaseState, StateKeys } from "../../redux/states/main.state";
import ApiEndpoint from "../../services/api.endpoint.service";
import {
  ClassContentDiscussionResponse,
  DiscussionCommentResponse,
} from "../../services/api.response.service";
import { IRootState } from "../../redux/states";
import { AppState } from "../../services/app.service";
import moment from "moment";

interface props {
  discussion: ClassContentDiscussionResponse;
  setDiscussion: Dispatch<
    SetStateAction<ClassContentDiscussionResponse | undefined>
  >;
  contentID: string | number;
}

const DiscussionReplies = ({ discussion, setDiscussion, contentID }: props) => {
  const { user } = useSelector((state: IRootState) => state.setting);
  const dispatch = useDispatch();
  const state = useSelector((state: IRootState) => state.main);
  const comments: BaseState<Array<DiscussionCommentResponse>> =
    state[StateKeys.CLASS_CONTENT_DISCUSSION_COMMENTS];
  const [pageComments, setPageComments] = useState<DiscussionCommentResponse[]>(
    []
  );

  const [comment, setComment] = useState<string>();

  const getDiscussionComments = (id: number) => {
    dispatch(
      Main.getPaginatedPageData({
        stateKey: StateKeys.CLASS_CONTENT_DISCUSSION_COMMENTS,
        apiEndpoint: ApiEndpoint.CONTENT_DISCUSSION_COMMENT(id),
        previousPage: 0,
        clear: true,
      })
    );
  };

  useEffect(() => {
    if (discussion) {
      getDiscussionComments(discussion?.id);
    }
  }, [discussion]);

  useEffect(() => {
    dispatch(
      Main.getPageArrayData({
        stateKey: StateKeys.CLASS_CONTENT_DISCUSSION,
        apiEndpoint: ApiEndpoint.CONTENT_DISCUSSION(Number(contentID)),
        clear: true,
      })
    );

    if (comments.pageState === AppState.LOADED) {
      setPageComments(comments.data);
      setComment("");
    }
  }, [comments]);

  const submit = (e: any) => {
    e.preventDefault();

    if (comment!.length <= 0) {
      return;
    }

    dispatch(
      Main.postPageData({
        stateKey: StateKeys.CLASS_CONTENT_DISCUSSION_COMMENTS,
        apiEndpoint: ApiEndpoint.CONTENT_DISCUSSION_COMMENT(
          Number(discussion?.id)
        ),
        req: { comment },
        append: "start",
        refresh: false,
      })
    );
  };

  const reportPost = (e: any) => {
    e.preventDefault();
    dispatch(
      Main.postPageData({
        stateKey: StateKeys.CLASS_CONTENT_DISCUSSION_COMMENTS,
        apiEndpoint: ApiEndpoint.CONTENT_DISCUSSION_REPORT(
          Number(discussion?.id)
        ),
        req: {  },
        append: "start",
        refresh: false,
      })
    );
  };

    const deletePost = (e: any) => {
        e.preventDefault();
        dispatch(
            Main.postPageData({
                stateKey: StateKeys.CLASS_CONTENT_DISCUSSION_COMMENTS,
                apiEndpoint: ApiEndpoint.CONTENT_DISCUSSION_DELETE(
                    Number(discussion?.id)
                ),
                req: {  },
                append: "start",
                refresh: false,
            })
        );
    };


  const reportComment = (id: any) : void|undefined => {
    // e.preventDefault();
    dispatch(
      Main.postPageData({
        stateKey: StateKeys.CLASS_CONTENT_DISCUSSION_COMMENTS,
        apiEndpoint: ApiEndpoint.CONTENT_DISCUSSION_COMMENT_REPORT(
          Number(id)
        ),
        req: {  },
        append: "start",
        refresh: false,
      })
    );
  };

  const deleteComment = (id: any): void|undefined => {
    // e.preventDefault();
    dispatch(
      Main.postPageData({
        stateKey: StateKeys.CLASS_CONTENT_DISCUSSION_COMMENTS,
        apiEndpoint: ApiEndpoint.CONTENT_DISCUSSION_COMMENT_DELETE(
          Number(id)
        ),
        req: {  },
        append: "start",
        refresh: false,
      })
    );
  };

  const upvoteComment = (id: any): void|undefined => {
    // e.preventDefault();
    dispatch(
      Main.postPageData({
        stateKey: StateKeys.CLASS_CONTENT_DISCUSSION_COMMENTS,
        apiEndpoint: ApiEndpoint.CONTENT_DISCUSSION_COMMENT_UPVOTE(
          Number(id)
        ),
        req: {  },
        append: "start",
        refresh: false,
      })
    );
  };

  const downvoteComment = (id: any): void|undefined => {
    // e.preventDefault();
    dispatch(
      Main.postPageData({
        stateKey: StateKeys.CLASS_CONTENT_DISCUSSION_COMMENTS,
        apiEndpoint: ApiEndpoint.CONTENT_DISCUSSION_COMMENT_DOWNVOTE(
          Number(id)
        ),
        req: {  },
        append: "start",
        refresh: false,
      })
    );
  };



  const conditions: string[] = ["minutes", "minute"];

  return (
    <>
      <Modal
        show={!!discussion}
        onHide={() => {
          setDiscussion(null!);
          setPageComments([]);
        }}
        centered
        scrollable
      >
        <Modal.Header closeButton></Modal.Header>
        <Modal.Body>
          <div style={{ height: "70vh" }} className="overflow-hidden ">
            <Card
              className="px-4 overflow-auto"
              style={{
                boxShadow: "none",
                border: "1px solid rgba(0, 0, 0, 0.10)",
                borderRadius: "5px",
                height: "62vh",
              }}
            >
              <div className="d-flex flex-column gap-3 mb-1 replies-container">
                <div className="d-flex flex-column mt-3">
                  <div className="d-flex justify-content-between">
                    <div className="d-flex align-items-center gap-1">
                      <img
                        className="discuss-profile"
                        style={{
                          width: "24px",
                          height: "24px",
                          display: "flex",
                          overflow: "hidden",
                          borderRadius: "50%",
                        }}
                        src={discussion?.user.image_url}
                        alt={discussion?.user.name}
                      />{" "}
                      <h5 style={{ fontWeight: 500, fontSize: "15px" }}>
                        {discussion?.user.name}
                      </h5>
                    </div>
                    <DropdownButton
                      drop={"start"}
                      variant={"transparent"}
                      title={<i className="las la-ellipsis-v"></i>}
                    >
                        {
                            user?.id != discussion?.user.id && (
                                <>
                                    <Dropdown.Item eventKey="1" onClick={reportPost}>Report</Dropdown.Item>
                                </>
                            )
                        }
                        {
                            user?.id === discussion?.user.id && (
                                <Dropdown.Item eventKey="1" onClick={deletePost}> Delete </Dropdown.Item>
                            )
                        }
                    </DropdownButton>
                  </div>

                  <p className="p-0 m-0">{discussion?.post}</p>
                </div>

                <div className="d-flex justify-content-end align-items-center gap-4">
                  <p style={{ color: "#9E9E9E" }}>
                    {conditions.some((el) =>
                      moment(new Date(discussion?.created_at))
                        .startOf("minute")
                        .fromNow()
                        .includes(el)
                    )
                      ? moment(new Date(discussion?.created_at))
                          .startOf("minute")
                          .fromNow()
                      : moment(new Date(discussion?.created_at)).format("ll")}
                  </p>{" "}
                </div>
              </div>

              <div>
                <hr className="mb-2 mt-0" />

                {pageComments.length === 0 &&
                comments.pageState === AppState.LOADING ? (
                  <div className="text-center">
                    <Spinner
                      animation={"border"}
                      size={"sm"}
                      className="me-1"
                    />
                  </div>
                ) : (
                  <div className="border-start border-danger px-2">
                    {pageComments.map((replies) =>
                        (
                            <div key={replies?.id}>
                                <div className="d-flex flex-column mb-1">
                                    <div className="d-flex flex-column">
                                        <div className="d-flex justify-content-between reply">
                                            <div className="d-flex align-items-center gap-1">
                                                <img
                                                    className="discuss-profile"
                                                    style={{
                                                        width: "24px",
                                                        height: "24px",
                                                        display: "flex",
                                                        overflow: "hidden",
                                                        borderRadius: "50%",
                                                    }}
                                                    src={replies?.user.image_url}
                                                    alt={replies?.user.name}
                                                />{" "}
                                                <h5
                                                    style={{ fontWeight: 500, fontSize: "15px" }}
                                                >
                                                    {replies?.user.name}
                                                </h5>
                                            </div>
                                            <DropdownButton
                                                drop={"start"}
                                                variant={"transparent"}
                                                title={<i className="las la-ellipsis-v"></i>}
                                            >
                                                {
                                                    user?.id != replies?.user.id && (
                                                        <>
                                                            <Dropdown.Item eventKey="1" onClick={() => reportComment(replies?.id)}> Report </Dropdown.Item>
                                                            <Dropdown.Item eventKey="1" onClick={() => upvoteComment(replies?.id)}>Upvote</Dropdown.Item>
                                                            <Dropdown.Item eventKey="1" onClick={() => downvoteComment(replies?.id)}>Downvote</Dropdown.Item>
                                                        </>
                                                    )
                                                }
                                                {
                                                    user?.id === replies?.user.id && (
                                                        <Dropdown.Item eventKey="1" onClick={() => deleteComment(replies?.id)}> Delete </Dropdown.Item>
                                                    )
                                                }
                                            </DropdownButton>
                                        </div>

                                        <p className="p-0 m-0">{replies?.comment}</p>
                                    </div>

                                    <div className="d-flex justify-content-end align-items-center">
                                        <p style={{ color: "#9E9E9E" }}>
                                            {conditions.some((el) =>
                                                moment(new Date(replies?.created_at))
                                                    .startOf("minute")
                                                    .fromNow()
                                                    .includes(el)
                                            )
                                                ? moment(new Date(replies?.created_at))
                                                    .startOf("minute")
                                                    .fromNow()
                                                : moment(new Date(replies?.created_at)).format(
                                                    "ll"
                                                )}
                                        </p>{" "}
                                    </div>
                                </div>
                                <hr className="m-0" />
                            </div>
                        )
                    )}
                  </div>
                )}
              </div>
            </Card>
          </div>
        </Modal.Body>
        <div className="position-absolute bottom-0 w-100 add-comment">
          <form onSubmit={submit}>
            <div className="row  align-items-center px-4 mt-2 position-relative">
              <div className="d-flex gap-3 align-items-center col-2">
                <div
                  className="profile-picture rounded-pill"
                  style={{ backgroundImage: `url(${user?.image_url})` }}
                ></div>

                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  width="20"
                  height="20"
                  fill="currentColor"
                  className="bi bi-emoji-smile"
                  viewBox="0 0 16 16"
                >
                  <path d="M8 15A7 7 0 1 1 8 1a7 7 0 0 1 0 14zm0 1A8 8 0 1 0 8 0a8 8 0 0 0 0 16z" />
                  <path d="M4.285 9.567a.5.5 0 0 1 .683.183A3.498 3.498 0 0 0 8 11.5a3.498 3.498 0 0 0 3.032-1.75.5.5 0 1 1 .866.5A4.498 4.498 0 0 1 8 12.5a4.498 4.498 0 0 1-3.898-2.25.5.5 0 0 1 .183-.683zM7 6.5C7 7.328 6.552 8 6 8s-1-.672-1-1.5S5.448 5 6 5s1 .672 1 1.5zm4 0c0 .828-.448 1.5-1 1.5s-1-.672-1-1.5S9.448 5 10 5s1 .672 1 1.5z" />
                </svg>
              </div>

              <div className="col-9">
                <textarea
                  className="form-control p-2"
                  name="post"
                  id="post"
                  placeholder="Add a comment..."
                  onChange={(e) => setComment(e.target.value)}
                  required
                  disabled={comments.pageState === AppState.LOADING}
                  value={comment}
                />
              </div>

              <div className="col-1">
                <svg
                  style={{ cursor: "pointer" }}
                  onClick={submit}
                  xmlns="http://www.w3.org/2000/svg"
                  width="24"
                  height="24"
                  fill="rgba(218, 55, 50, 1)"
                  className="bi bi-send ms-3"
                  viewBox="0 0 16 16"
                >
                  <path d="M15.854.146a.5.5 0 0 1 .11.54l-5.819 14.547a.75.75 0 0 1-1.329.124l-3.178-4.995L.643 7.184a.75.75 0 0 1 .124-1.33L15.314.037a.5.5 0 0 1 .54.11ZM6.636 10.07l2.761 4.338L14.13 2.576 6.636 10.07Zm6.787-8.201L1.591 6.602l4.339 2.76 7.494-7.493Z" />
                </svg>{" "}
              </div>
            </div>{" "}
          </form>
        </div>
      </Modal>
    </>
  );
};

export default DiscussionReplies;
